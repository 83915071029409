.loader-root {
    display: flex;
    position: fixed;
    z-index: 1400;
    inset: 0;
}

.loader-root .backdrop-root{
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: -1;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
}

.loader {
    animation: spinningColor 1.5s ease-in-out infinite;
    margin: auto;
    border: 5px double #f0eff5;
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

@keyframes spinningColor {
    0% {
        transform: rotate(360deg);
        border-top: 5px dashed #f56682;
        border-bottom: 5px dashed #387eff;
    }
    25% {
        border-top: 5px dashed #f591a6;
        border-bottom: 5px dashed #6da7f7;
    }
    50% {
        border-top: 5px dashed #fd878e;
        border-bottom: 5px dashed #4ba3ff;
    }
    75% {
        border-top: 5px dashed #f57f8f;
        border-bottom: 5px dashed #569dff;
    }
    100% {
        border-top: 5px dashed #f56682;
        border-bottom: 5px dashed #387eff;
    }
}